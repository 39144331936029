import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "./tulokset.module.css"

export default function ResultsPage(props) {
    const [pageContent, setPageContent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const isBrowser = typeof window !== 'undefined';

    useEffect(() => {
        try {
            if (!isBrowser) return;

            async function fetchData() {
                // fetch data from DB
                try {
                    let apiUrl = window.location.href.indexOf('localhost') > -1
                        ? 'http://localhost:7071/api'
                        : window.location.href.indexOf('azurestaticapps') > -1 ?
                            'https://apim-ruoka.azure-api.net/kouluruoka-api' : '/api';

                    let menu = await window.fetch(apiUrl + '/monthlyfavorites');

                    let res = await menu.json();
                    res.Month = ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'][res.Month];

                    setPageContent(res);
                    setIsLoading(false);
                }
                catch (error) {
                    console.log('Error fetching monthlyfavorites')
                }
            }

            fetchData();
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        try {
            if (!isBrowser) return;

            const adsbygoogle = window.adsbygoogle || [];
            adsbygoogle.push({});
        } catch (e) {
            console.error(e);
        }
    }, [])

    return (
        <Layout>
            <SEO title={`${pageContent.Month}kuun ruokaäänestyksen tulokset`} description={`Katso ${pageContent.Month ? pageContent.Month.toLowerCase() : ''}kuun suosikki- ja inhokkiruoat`} />
            {
                isLoading ? <p>Haetaan suosikkeja ja inhokkeja...</p> :
                    <>
                        <h1>{pageContent.Month}kuun suosikit ja inhokit</h1>
                        <h2>Suosikit ❤️</h2>
                        <ol>
                            {pageContent.Liked && pageContent.Liked.length > 0 ? pageContent.Liked.map((meal, meal_i) => (
                                // remove allergens and extra commas
                                <li key={meal_i}>{meal}</li>
                            )) : <li>Suosikkeja ei löytynyt, perin outoa, selvittelemme ongelmaa...</li>}
                        </ol>
                        <h2>Inhokit 🤢</h2>
                        <ol>
                            {pageContent.Disliked && pageContent.Disliked.length > 0 ? pageContent.Disliked.map((meal, meal_i) => (
                                // remove allergens and extra commas
                                <li key={meal_i}>{meal}</li>
                            )) : <li>Inhokkeja ei löytynyt, kummallista, selvittelemme ongelmaa...</li>}
                        </ol>
                    </>
            }
            <div id='adbanner'>
                <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
                    data-ad-client="ca-pub-2668838512868610"
                    data-ad-slot="5745330552"
                    data-ad-format="horizontal,auto"
                    data-full-width-responsive="true"></ins>
            </div>
        </Layout>
    )
}
